<template>
  <div>
    <Swiper
      :slides-per-view="'auto'"
      class="swiper-hero"
      :modules="[SwiperPagination, SwiperAutoplay]"
      :pagination="{
        type: 'bullets',
      }"
      :loop="true"
      :autoplay="{
        delay: 8000,
        disableOnInteraction: false,
      }"
    >
      <SwiperSlide
        v-for="(heroBlock, index) in data.CarouselItems"
        :key="index"
        class="w-full aspect-[1/2] sm:aspect-2/1 relative max-h-[85vh] lg:max-h-[70vh] md:min-h-[600px]"
      >
        <HeroBlock
          :key="index"
          :is-edit-mode="isEditMode"
          :is-mobile="isMobile"
          :block="heroBlock"
        />
        <div class="layout-container" />
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script setup lang="ts">
import { type HeroCarouselBlockFragment } from '#gql';
import { useGlobalContentStore } from '~/store/globalContent';

const config = useGlobalContentStore().config;

const props = defineProps<{
  block: HeroCarouselBlockFragment,
  isMobile: boolean,
  isEditMode: boolean,
}>();

const data = computed(() => {
  return props.block as HeroCarouselBlockFragment;
});

</script>

<style>
.swiper-hero {
  .swiper-pagination-bullets {
    @apply max-w-1372 bottom-24 sm:bottom-32 px-24 md:px-56 left-1/2 transform -translate-x-1/2 text-left md:bottom-64;
  }
}
</style>
